<template>
    <div class="register">
        <TopbarSP style="box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);"/>
        <div class="main">
            <div class="register-box">
                <!-- 左侧图片 -->
                <div class="image-wrap">
                    <img src="../../assets/humanResources/register_form.png" alt="">
                </div>
                <!-- 右侧表单 -->
                <div class="form-wrap">
                    <div class="title">欢迎使用安知人力资源服务平台</div>
                    <el-form ref="ruleForm" :model="ruleForm" size="small" :rules="rules">
                        <el-form-item prop="mobile">
                            <el-input v-model="ruleForm.mobile" @focus="clearValidate('mobile')" @input="filterInput('mobile')" clearable placeholder="手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="ruleForm.password" autocomplete="new-password" @focus="clearValidate('password')" clearable placeholder="密码" type="password"></el-input>
                        </el-form-item>
                        <el-form-item prop="repeatPassword">
                            <el-input v-model="ruleForm.repeatPassword" @focus="clearValidate('repeatPassword')" clearable placeholder="确认密码" type="password"></el-input>
                        </el-form-item>
                        <el-form-item prop="captcha">
                            <div class="vcode-wrap">
                                <el-input class="vcode-input" @focus="clearValidate('captcha')" @input="filterInput('captcha')" clearable v-model="ruleForm.captcha" placeholder="验证码"></el-input>
                                <div class="Vcode">
                                    <div class="line"></div>
                                    <div v-if="isCode" class="get-code" style="color:#C0C4CC">{{time+"s"}}</div>
                                    <div v-else class="get-code" @click="getVCode">获取验证码</div>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button :disabled="disabled" :style="disabled?'':available" @click="register">注册</el-button>
                        </el-form-item>
                        <el-form-item prop="agree">
                            <div class="agree">
                                <el-checkbox v-model="ruleForm.agree"></el-checkbox>
                                <div>请阅读并同意<span @click="jump(1)">《用户服务协议》</span>和<span @click="jump(2)">《隐私政策》</span></div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <Footbar/>
    </div>
</template>

<script>
    import TopbarSP from "../../components/HumanResources/TopbarSP.vue";
    import Footbar from "../../components/HumanResources/Footbar.vue";
    export default {
        name:"Register",
        components:{
            TopbarSP,
            Footbar
        },
        data(){
            // 验证手机号
            const validateMobile=(rule,value,callback)=>{
                const reg=/^1[3456789]\d{9}$/;
                if(!reg.test(value)){
                    callback("请输入正确的手机号");
                    return;
                }
                callback();
            }
            // 验证验证码
            const validateSmsCode=(rule,value,callback)=>{
                const reg=/^\d{6}$/;
                if(!reg.test(value)){
                    callback("请输入正确的验证码");
                    return;
                }
                callback();
            }
            // 校验密码
            const validPassword=(rule,value,callback)=>{
                let result = this.checkPassword(value);
                if (!result.flag){
                    callback(new Error(result.msg));
                }else{
                    callback();
                }
            }
            // 确认密码
            const validateRepeatPassword=(rule,value,callback)=>{
                const {ruleForm}=this;
                if(!ruleForm.password && !value){
                    callback();
                    return;
                }
                if(ruleForm.password!==value){
                    callback("两次输入密码不一致");
                    return;
                }
                callback();
            }
            // 勾选协议校验
            const validateAgree=(rule,value,callback)=>{
                if(!value){
                    callback("请阅读并勾选同意");
                    return;
                }
                callback();
            }
            return{
                // 表单数据
                ruleForm:{
                    captcha:"", // 验证码
                    mobile:"",// 手机号
                    password:"",// 密码
                    repeatPassword:"",// 确认密码
                    agree:false,// 是否同意协议
                },
                // 表单校验规则
                rules:{
                    mobile:[
                        {required:true,message:"请输入手机号",trigger:"blur"},
                        {validator:validateMobile,trigger:"blur"}
                    ],
                    password:[
                        {required:true,message:"请输入密码",trigger:"blur"},
                        {validator:validPassword,trigger:"blur"}
                    ],
                    captcha:[
                        {required:true,message:"请输入验证码",trigger:"blur"},
                        {validator:validateSmsCode,trigger:"blur"}
                    ],
                    repeatPassword:[
                        {validator:validateRepeatPassword,trigger:"blur"}
                    ],
                    agree:[
                        {validator:validateAgree,trigger:"blur"}
                    ]
                },
                // 倒计时时间
                time:180,
                // 定时器
                timer:null,
                // 是否正在发送验证码
                isCode:false,
                // 注册按钮是否可用
                disabled:true,
                // 按钮可用时颜色
                available:{
                    "backgroundColor":"#2878FF",
                    "color":"white"
                }
            }
        },
        watch:{
            // 监听按钮是否可用
            ruleForm:{
                handler:function(obj){
                    if(obj.captcha&&obj.mobile&&obj.password&&obj.repeatPassword){
                        this.disabled=false;
                    }else{
                        this.disabled=true;
                    }
                },
                deep:true,
                immediate:true
            }
        },
        methods:{
            // 获取验证码
            getVCode(){
                const {ruleForm}=this;
                this.$refs.ruleForm.validateField("mobile",valid=>{
                    if(!valid){
                        // 倒计时
                        clearInterval(this.timer);
                        this.isCode=true;
                        this.timer=setInterval(()=>{
                            this.time=this.time-1;
                            if(this.time<0){
                                this.isCode=false;
                                this.time=180;
                                clearInterval(this.timer);
                            }
                        },1000);
                        this.$post("/sys/sms/register/captcha",{
                            mobile:ruleForm.mobile
                        }).then(()=>{
                            this.$message.success("验证码已发送至您的手机，请注意查收");
                        }).catch(()=>{
                            this.isCode=false;
                            this.time=180;
                            clearInterval(this.timer);
                        });
                    }
                });
            },
            // 点击注册
            register(){
                const {ruleForm}=this;
                this.$refs.ruleForm.validate(valid=>{
                    if(valid){
                        this.$post("/hr/registry",{
                            mobile:ruleForm.mobile,
                            password:ruleForm.password,
                            captcha:ruleForm.captcha
                        }).then(()=>{
                            this.$router.push({
                                path:"/web/login"
                            });
                        });
                    }
                });
            },
            // 清除某一项认证规则
            clearValidate(key){
                this.$refs.ruleForm.clearValidate(key);
            },
            // 过滤非法字符
            filterInput(key){
                const {ruleForm}=this;
                const reg=/\D*/ig;
                ruleForm[key]=ruleForm[key].replace(reg,"");
            },
            // 密码规则
            checkPassword(str) {
                //校验密码，密码长度8-20，必须包含大写字母、小写字母、数字、特殊符号中的三种
                let rC = {
                    lW: "[a-z]",
                    uW: "[A-Z]",
                    nW: "[0-9]",
                    sW: "[\\u0020-\\u002F\\u003A-\\u0040\\u005B-\\u0060\\u007B-\\u007E]"
                }
                function Reg(str, rStr) {
                    let reg = new RegExp(rStr);
                    if (reg.test(str)) return true;
                    else return false;
                }
                if (str.length < 8) {
                    return { flag: false, msg: "密码长度不得少于8位" };
                } else if (str.length > 20) {
                    return { flag: false, msg: "密码长度不得超过20位" };
                } else {
                    let tR = {
                        l: Reg(str, rC.lW),
                        u: Reg(str, rC.uW),
                        n: Reg(str, rC.nW),
                        s: Reg(str, rC.sW)
                    }
                    if (
                        (tR.l && tR.u && tR.n) ||
                        (tR.l && tR.u && tR.s) ||
                        (tR.s && tR.u && tR.n) ||
                        (tR.s && tR.l && tR.n))
                    {
                        return { flag: true, msg: "" };
                    }else{
                        return {
                            flag: false,
                            msg:"密码需由 大、小写字母 数字 特殊符号 中任意三种"
                        }
                    }
                }
            },
            // 跳转隐私协议和服务
            jump(type){
                // 服务
                if(type===1){
                    window.open("https://anzhi.bjsrxx.com/banner/anzhi-humanResources-sa.html");
                }else{
                    // 隐私
                    window.open("https://anzhi.bjsrxx.com/banner/anzhi-humanResources-pa.html");
                }
            }
        },
        beforeDestroy(){
            clearInterval(this.timer);
        }
    }
</script>

<style scoped lang="less">
    .register{
        height: 100vh;
        overflow: hidden;
        .main{
            height: calc(100vh - 10.875rem);
            background-image: url("../../assets/humanResources/registerBackground.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom center;
            display: flex;
            justify-content: center;
            align-items: center;
            .register-box{
                height: 27.8125rem;
                width: 54.125rem;
                background: #FFFFFF;
                box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
                border-radius: 8px;
                margin-top: -7.125rem;
                display: flex;
                // 左侧图片
                .image-wrap{
                    height: 100%;
                    width: 27.8125rem;
                    flex-shrink: 0;
                    overflow: hidden;
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
                .form-wrap{
                    flex-grow: 1;
                    flex-shrink: 0;
                    padding: 3.75rem;
                    box-sizing: border-box;
                    .title{
                        font-size: 1.125rem;
                        font-weight: 600;
                        color: #333333;
                        text-align: center;
                    }
                    // 表单
                    /deep/.el-form{
                        margin-top: 1.875rem;
                        .el-input--small{
                            .el-input__inner{
                                height: 2.125rem;
                                line-height: 2.125rem;
                            }
                        }
                        // 验证码的输入框
                        .vcode-wrap{
                            position: relative;
                            .vcode-input{
                                .el-input__inner{
                                    padding-right: 7.5rem;
                                }
                                .el-input__suffix{
                                    right: 6rem;
                                }
                            }
                            .Vcode{
                                display: flex;
                                align-items: center;
                                height: 100%;
                                position: absolute;
                                right: 0;
                                top: 0;
                                cursor: pointer;
                                .line{
                                    width: 1px;
                                    height: 1.25rem;
                                    background: #DDDDDD;
                                }
                                .get-code{
                                    font-size: .875rem;
                                    color: #2878FF;
                                    width: 90px;
                                    text-align: center;
                                }
                            }
                        }
                        .el-button{
                            border: none;
                            width: 100%;
                            height: 2.125rem;
                            background: #F5F5F5;
                            border-radius: 2px;
                            color:#999999;
                            transition: 0.3s;
                        }
                        .agree{
                            display: flex;
                            align-items: center;
                            font-size: .75rem;
                            color: #999999;
                            .el-checkbox{
                                margin-right: 3px;
                            }
                            span{
                                color: #2878FF;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
</style>